<script>
  import { useLocation, link } from "svelte-navigator";
  import { useParams } from "svelte-navigator";

  $: fetchedData = null;
  const fetchData = async () => {
    const response = await fetch("/data/bureaux.json");
    const res = await response.json();
    let membreData = { all: res };
    for (let index = 0; index < res.length; index++) {
      const element = res[index];
      if (element.slug === id) {
        membreData.current = element;
        membreData.index = index;
        membreData.next = res[index + 1] && res[index + 1].slug;
        membreData.prev = res[index - 1] && res[index - 1].slug;
        break;
      }
    }
    fetchedData = membreData;

    return membreData;
  };
  // fetchedData = fetchData();
  const params = useParams();
  const location = useLocation();
  $: {
    console.log($params);
    console.log($location);
    fetchedData = fetchData();
  }

  export let id;
</script>

<svelte:head>
  <title
    >Maison 48 | {fetchedData &&
      fetchedData.current &&
      fetchedData.current.name.replace("&nbsp;", " ")}</title
  >
  <html lang="fr" />
</svelte:head>

<section>
  <div class="main">
    {#await fetchedData}
      <h1>...</h1>
    {:then data}
      <div class="container" style="align-items: start; ">
        <div style="margin-right:50px;">
          <div class="info">
            {#if data.prev}
              <a class="prev" href="/bureaux/{data.prev}" use:link>Précédent</a>
            {/if}
            {#if data.prev && data.next}
              <span style="padding:0 10px">|</span>
            {/if}
            {#if data.next}
              <a class="next" href="/bureaux/{data.next}" use:link>Suivant</a>
            {/if}
          </div>
          <h1>{@html data.current.name}</h1>
          <span>Superficie: {data.current.superficie}</span>
          <p>{data.current.desc}</p>
          <div>
            <span class="details">{data.current.tarif_1}</span>
            <span class="details">{data.current.tarif_2}</span>
          </div>
          <a href="/contact"
            ><button class="btn primary" style="margin-top:15px"
              >Contactez-nous</button
            ></a
          >
        </div>

        <div
          class="slideshow header_slideshow"
          style="background-image: url(/img/bureaux/{data.current.picture_1});"
        />
      </div>
      <div class="container bureau" style="padding-top:15px;">
        <div
          class="slideshow header_slideshow"
          style="background-image: url(/img/bureaux/{data.current.picture_2});"
        />
        <div
          class="slideshow header_slideshow"
          style="background-image: url(/img/bureaux/{data.current.picture_3});"
        />
      </div>
    {/await}
  </div>
</section>

<style>
  .details {
    margin: 5px 0px 0px;
    font-size: 0.9em;
    background-color: rgb(142 112 44);
    display: inline-block;
    padding: 4px 8px;
    border-radius: 5px;
    color: white;
  }
  .next:after {
    display: inline-block;
    content: "";
    background-image: url(/img/icons/next.svg);
    background-size: 0.6em 0.6em;
    height: 1em;
    width: 1em;
    background-repeat: no-repeat;
    background-position: 0% bottom;
  }
  .prev:before {
    display: inline-block;
    content: "";
    background-image: url(/img/icons/prev.svg);
    background-size: 0.6em 0.6em;
    height: 1em;
    width: 0.6em;
    background-repeat: no-repeat;
    background-position: 0% bottom;
  }

  .info {
    margin-bottom: 20px;
    padding-top: 5vh;
  }

  .header_slideshow {
    height: 600px;
    width: 100%;
    background-size: cover;
    background-position: center;
  }

  section {
    padding: 0px;
  }
  @media (max-width: 500px) {
    .header_slideshow {
      height: 250px;
    }
    .info {
      margin-bottom: 20px;
      padding-top: 2%;
    }
  }
</style>
