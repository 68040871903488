<script>
  import Bureau from "../components/Bureau.svelte";

  const fetchData = (async () => {
    const response = await fetch("/data/bureaux.json");
    const res = await response.json();
    return res;
  })();
</script>

<section>
  <div class="main">
    <h1>Les bureaux</h1>
    <div class="grid intro" style="padding-bottom:80px">
      <div>
        <p>
          Nous avons aménagé nos bureaux pour qu'ils ressemblent le moins
          possible à des bureaux. Nous les avons voulus comfortables,
          accuillants et chaleureux.
        </p>
        <p>
          Toute location donne accès : Au jardin, à la cuisine / espace de
          repos, à une imprimante et bien évidemment au réseau WIFI.
        </p>
      </div>
      <div>
        <p>
          Le système de location se fait par plages horaires de minimum 4h, ces
          plages sont fixes (des locations à l'heure restent cependant
          possibles):
        </p>
        <ul>
          <li>08h30 - 12h30</li>
          <li>13h00 - 17h00</li>
          <li>17h30 - 21h30</li>
        </ul>
        <p />
      </div>
    </div>
    <div class="grid image">
      {#await fetchData}
        <p>...waiting</p>
      {:then data}
        {#each data as data, i}
          <Bureau {data} />
        {/each}
      {:catch error}
        <p>An error occurred! {error}</p>
      {/await}
    </div>
  </div>
</section>

<svelte:head>
  <title>Maison 48 | Les bureaux</title>
  <html lang="fr" />
</svelte:head>

<style>
  .grid {
    column-gap: 70px;
    display: grid;
    grid-template: auto / repeat(auto-fit, minmax(340px, 1fr));
  }
  .grid.image {
    gap: 60px;
  }

  .grid.intro {
    max-width: 1200px;
  }
  section {
    padding-top: 160px;
  }
  @media (max-width: 500px) {
    .grid {
      column-gap: 70px;
      display: grid;
      grid-template: auto / auto;
    }
  }
</style>
