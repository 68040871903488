<script>
  import { link } from "svelte-navigator";

  export let data;
</script>

<div>
  <a href="/bureaux/{data.slug}" style="height:250px" use:link>
    <img class="thumb" src="/img/bureaux/{data.picture_1}" alt="les bureaux" />
  </a>
  <div class="desc">
    <h2>{@html data.name}</h2>
    <span>{data.superficie}</span>
    <span>{data.tarif_1}</span>
    <span>{data.tarif_2}</span>
    <a class="link-info" href="/bureaux/{data.slug}" use:link>Plus d'infos</a>
  </div>
</div>

<style>
  h1 {
    margin-top: 25px;
  }
  .thumb {
    box-shadow: white 10px 10px 0px 0px, #8d7053 11px 11px;
    transition: box-shadow 0.15s ease-in-out, margin 0.15s ease-in-out;
    width: 350px;
    height: 240px;
  }
  .thumb:hover {
    box-shadow: white 5px 5px 0px -5px, #8d7053 5px 5px;
    margin-left: 5px;
    margin-right: -5px;
  }
  .desc {
    border-left: 1px solid #8d7053;
    margin-top: 0px;
    padding-left: 15px;
    width: 85%;
    margin-left: 11px;
  }

  .link-info {
    margin-top: 5%;
  }

  .link-info:after {
    display: inline-block;
    content: "";
    background-image: url(/img/icons/next.svg);
    background-size: 0.6em 0.6em;
    height: 1em;
    width: 1em;
    background-repeat: no-repeat;
    background-position: 0% bottom;
  }
  div {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  @media (max-width: 500px) {
    .thumb {
      width: 100%;
      height: 240px;
    }
  }
</style>
