<script>
  import { Link } from "svelte-navigator";
  import Logo from "./Logo.svelte";

  let menuOpen = false;
  import { useLocation } from "svelte-navigator";

  const location = useLocation();

  let color = "white";

  $: {
    console.log($location);
    if ($location.pathname !== "/") {
      console.log("not home");
      color = "black";
    } else {
      color = "white";
    }
    menuOpen = false;
  }

  const handleMouseDown = (e) => {
    menuOpen = true;
  };
</script>

<Logo />
<nav class="nav">
  {#if !menuOpen}
    <div class="menu-icon" on:mousedown={handleMouseDown}>
      <svg
        width="70"
        height="70"
        viewBox="0 0 100 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="30.7748"
          y="33.2933"
          width="38.4503"
          height="5.39848"
          rx="2.69924"
          fill={color}
        />
        <rect
          x="30.7748"
          y="47.3008"
          width="38.4503"
          height="5.39848"
          rx="2.69924"
          fill={color}
        />
        <rect
          x="30.7748"
          y="61.3082"
          width="38.4503"
          height="5.39848"
          rx="2.69924"
          fill={color}
        />
      </svg>
    </div>
  {:else}
    <div
      class="close-icon"
      on:mousedown={() => {
        menuOpen = false;
      }}
    >
      <svg
        width="70"
        height="70"
        viewBox="0 0 70 70"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="22.3459"
          y="44.5383"
          width="31.3847"
          height="4.40645"
          rx="2.20323"
          transform="rotate(-45 22.3459 44.5383)"
          fill="white"
        />
        <rect
          width="31.3847"
          height="4.40645"
          rx="2.20323"
          transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 47.6541 44.5383)"
          fill="white"
        />
      </svg>
    </div>

    <div class="menu">
      <Link
        to="membres"
        class="underline navigation-small-item"
        style="margin-left:25px">Les thérapeutes</Link
      >
      <Link class="underline navigation-small-item" to="bureaux"
        >Les bureaux</Link
      >
      <Link class="underline navigation-small-item" to="info_pratiques"
        >Infos pratiques</Link
      >
      <Link class="underline navigation-small-item" to="contact">Contact</Link>
    </div>
  {/if}
</nav>

<style>
  nav {
    display: flex;
    width: 100%;

    justify-content: end;
    align-items: center;
    position: absolute;
    top: 0px;
    left: 0px;
  }

  .menu {
    font-size: 1.25em;
    position: fixed;
    height: 100%;
    width: 100%;
    background: black;
    top: 0px;
    right: 0px;
    z-index: 1000;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .close-icon {
    height: 70px;
    width: 70px;
    z-index: 3000;
  }

  .menu-icon {
    height: 70px;
    width: 70px;
    z-index: 3000;
  }
</style>
