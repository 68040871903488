<script>
  import { Link } from "svelte-navigator";

  export let title;
  export let name;
  export let pic;
</script>

<div>
  <h2>{name}</h2>
  <Link to="/membres/{name.replaceAll(' ', '-')}">
    <img
      class="membre"
      src="/img/membres/{pic}"
      alt="membre equipe"
      style="object-fit:cover; height:300px; width:220px"
    /></Link
  >

  <p class="title">{title}</p>
  <Link class="underline" to="/membres/{name.replaceAll(' ', '-')}"
    >Plus d'info</Link
  >
</div>

<style>
  .membre {
    box-shadow: white 11px 11px 0px -1px, #8d7053 11px 11px;
    margin-left: -12px;
    margin-bottom: 10px;
    transition: box-shadow 0.15s ease-in-out, margin 0.15s ease-in-out;
  }
  .membre:hover {
    box-shadow: white 5px 5px 0px -5px, #8d7053 5px 5px;
    margin-left: -5px;
  }

  .title {
    max-width: 250px;
  }

  @media (max-width: 500px) {
    .title {
      max-width: 100%;
    }
  }

  h2 {
    margin: 10px 0;
  }
  div {
    width: 100%;
  }
  p {
    margin: 10px 0px;
  }
</style>
