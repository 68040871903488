<script>
  import { onMount } from "svelte";

  import { link } from "svelte-navigator";
  import { useParams } from "svelte-navigator";
  import membres_data from "../membres";

  const params = useParams();
  let membreData;
  onMount(() => {
    window.scrollTo(0, 0);
  });
  $: {
    console.log("params", $params.id);
    membreData = membres_data.find(
      (o) => o.name === $params.id.replaceAll("-", " ")
    );
  }
</script>

<svelte:head>
  <title>Maison 48 | {membreData && membreData.name}</title>
  <html lang="fr" />
</svelte:head>

<section>
  <div class="main">
    <div class="container details">
      <div class="info">
        <div class="prev">
          <a href="/membres/" use:link>Les thérapeutes</a>
        </div>
        <h1>{membreData.name}</h1>
        <h2>{membreData.title}</h2>
        <p>{@html membreData.desc}</p>
      </div>
      <div
        class="picture-membre"
        style="background-image: url(/img/membres/{membreData.pic})"
      />
    </div>
  </div>
</section>

<style>
  .picture-membre {
    width: 350px;
    height: 500px;
    background-size: cover;
    box-shadow: white 10px 10px 0px 0px, #8d7053 11px 11px;
  }
  .details {
    align-items: flex-start;
    grid-template-columns: 1fr 0.5fr;
  }

  .info {
    padding-right: 10%;
  }
  .prev:before {
    display: inline-block;
    content: "";
    background-image: url(/img/icons/prev.svg);
    background-size: 0.6em 0.6em;
    height: 1em;
    width: 0.6em;
    background-repeat: no-repeat;
    background-position: 0% bottom;
  }

  .prev {
    margin-bottom: 20px;
  }
  section {
    padding: 0px;
  }
  @media (max-width: 500px) {
    .details {
      grid-template-columns: 1fr;
      width: 100%;
    }

    .picture-membre {
      width: 100%;
      height: 400px;
      background-size: cover;
    }
  }
</style>
