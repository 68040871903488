<script>
  import { Link, useLocation } from "svelte-navigator";
  import { onMount } from "svelte";
  let innerHeight;
  const handleResize = (e) => {
    let vh = window.innerHeight * 0.01;
    innerHeight = window.innerHeight;
    console.log("--vh", innerHeight);
    // Then we set the value in the --vh custom property to the root of the document
    // document.documentElement.style.setProperty("--vh", `${vh}px`);
  };
  onMount(() => {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    handleResize();
  });
</script>

<svelte:window on:resize={handleResize} />
<svelte:head>
  <title>Maison 48 | accueil</title>
  <html lang="fr" />
</svelte:head>

<section>
  <div class="container">
    <div class="intro">
      <h1>
        La Maison 48<br />
        Psycho/thérapies du Mieux-être
      </h1>
      <div class="intro-sections">
        <div>
          <p>Découvrez notre équipe de psychologues et thérapeutes</p>
          <Link class="main-link" to="membres">tous les thérapeutes</Link>
        </div>
        <div>
          <p>
            Nous mettons en location des bureaux adaptés: chaleureux, calmes et
            lumineux
          </p>
          <Link class="main-link" to="bureaux">voir les bureaux</Link>
        </div>
      </div>
    </div>
    <div class="slideshow home" />
  </div>
</section>

<style>
  .intro {
    max-width: 45vw;
    margin-top: 30px;
    margin-left: 100px;
  }

  .intro-sections {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 45px;
  }

  section {
    padding: 0px;
  }
  .container {
    height: 100%;
    padding: 20px;
    width: 100%;
    min-height: 100vh;
    position: absolute;
  }

  @media only screen and (max-width: 450px) {
    .intro {
      max-width: calc(100% - 60px);
      margin-top: 180px;
      margin-left: 30px;
      bottom: 40px;
      position: absolute;
    }

    .intro-sections {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 25px;
    }

    h1 {
      font-size: 1.6em;
      line-height: 1em;
    }
    section {
      color: white;
    }
    .container {
      height: 100%;
      position: absolute;
      padding: 0px;
      /* display: flex;

      width: 100%;
      padding: 0px; */
    }
  }
</style>
