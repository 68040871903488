<script>
  import { onMount } from "svelte";

  import Membre from "../components/Membre.svelte";
  import membres_data from "../membres";
  onMount(() => {
    window.scrollTo(0, 0);
  });
</script>

<svelte:head>
  <title>Maison 48 | Les thérapeutes</title>
  <html lang="fr" />
</svelte:head>

<section>
  <div class="main">
    <h1>Les thérapeutes</h1>
    <div class="grid">
      {#each membres_data as { title, name, pic }, i}
        <Membre {title} {name} {pic} />
      {/each}
    </div>
  </div>
</section>

<style>
  .grid {
    gap: 5vw;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
  section {
    padding-top: 50px;
  }
  @media (max-width: 500px) {
    .main {
      text-align: center;
    }
    section {
      padding-top: 160px;
    }
  }
</style>
