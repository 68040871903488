<script>
  import { navigate } from "svelte-navigator";
  let email;
  let message;
  let phone;
  let name;

  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  };

  async function handleSubmit(e) {
    console.log("SENDING", name, email, message, phone);
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", name, email, message, phone }),
    })
      .then(() => navigate("/confirm", { replace: true }))
      .catch((error) => alert(error));

    e.preventDefault();
  }
</script>

<svelte:head>
  <title>Maison 48 | contact</title>
  <html lang="fr" />
</svelte:head>

<section>
  <div class="container">
    <div>
      <h1>Contactez-nous</h1>
      <p>Besoin de plus d'infos ou de planifier une visite?</p>
      <form on:submit={handleSubmit}>
        <input type="hidden" name="form-name" value="contact" />

        <label for="fname">Votre nom</label>
        <input
          bind:value={name}
          type="text"
          id="fname"
          name="name"
          placeholder="Entrez votre nom"
          minlength="3"
          required
        />

        <label for="lname">Votre email</label>
        <input
          type="email"
          id="email"
          name="email"
          placeholder="Entrez votre email."
          bind:value={email}
          required
        />

        <label for="lname">Votre téléphone</label>
        <input
          type="tel"
          id="phone"
          name="phone"
          placeholder="Entrez votre numéro de tel."
          required
          bind:value={phone}
        />

        <label for="subject">Votre message</label>
        <textarea
          id="subject"
          name="message"
          placeholder="Tapez votre message..."
          style="height:120px; width:100%"
          required
          bind:value={message}
        />

        <input type="submit" value="Envoyer" class="btn primary" />
      </form>
    </div>
    <div class="slideshow home" />
  </div>
</section>

<style>
  section {
    padding: 0px;
    padding-left: 100px;
  }
  .container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
    grid-template-rows: minmax(auto, 1fr);
    align-items: center;
    justify-content: space-between;
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    gap: 15px;
    min-height: 780px;
  }

  .slideshow {
    background: url(/img/contact.jpg);
    background-size: cover;
    min-height: 540px;
    width: 90%;
  }
  form {
    display: flex;
    flex-direction: column;
  }
  input {
    width: 100%;
  }

  @media (max-width: 1045px) {
    .container {
      position: relative;
    }
    .slideshow {
      width: 100%;
    }
  }
  @media (max-width: 500px) {
    .container {
      display: flex;
      /* grid-template-columns: repeat(auto-fit, minmax(450px, 1fr)); */
    }
    section {
      padding: 0px;
      padding-left: 0px;
    }
    .slideshow {
      width: calc(100% - 20px);
      background: none;
    }
  }
</style>
