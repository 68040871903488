<script>
  import { Router, Route } from "svelte-navigator";
  import Home from "./routes/Home.svelte";
  import Membres from "./routes/Membres.svelte";
  import Navigation from "./components/Navigation.svelte";
  import NavigationSmall from "./components/NavigationSmall.svelte";
  import Bureaux from "./routes/Bureaux.svelte";
  import BureauDetails from "./routes/BureauDetails.svelte";
  import MembreDetails from "./routes/MembreDetails.svelte";
  import Info from "./routes/Info.svelte";
  import Contact from "./routes/Contact.svelte";
  import Confirm from "./routes/Confirm.svelte";
  let windowWidth;
</script>

<svelte:window bind:innerWidth={windowWidth} />
<Router>
  {#if windowWidth >= 500}
    <Navigation />
  {:else}
    <NavigationSmall />
  {/if}
  <div>
    <Route path="/">
      <Home />
    </Route>
    <Route path="confirm/" component={Confirm} />
    <Route path="membres/" component={Membres} />

    <Route path="contact/" component={Contact} />
    <Route path="info_pratiques/" component={Info} />
    <Route path="bureaux/*">
      <Route path="/" component={Bureaux} />
      <Route path=":id" let:params let:navigate
        ><BureauDetails id={params.id} {navigate} /></Route
      >
    </Route>
    <Route path="membres/:id" let:params><MembreDetails id={params.id} /></Route
    >
    <!-- <footer>La Maison 48 ©2021</footer> -->
  </div>
</Router>
